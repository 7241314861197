import { updateSelectorClasses } from '../../lib/file_upload.js';

export default function setUpExpressEmbed() {
  ((document, url) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = async () => {
      if (!window.CCEverywhere) {
        return;
      }

      const ccEverywhere = await window.CCEverywhere.initialize({
        // TODO: store in env variables
        clientId: '7d291c3bbcd14485bb4ab008f2931091',
        appName: 'Stornaway Adobe Express Projects',
      });

      // Create Image Button Selector
      const createAdobeImageEl = '.js-create-adobe-image';
      // Create Video Button Selector
      const createAdobeVideoEl = '.js-create-adobe-video';

      // Edit Image Button Selector
      const editAdobeImageEl = '.js-edit-adobe-image';
      // Edit Video Button Selector
      const editAdobeVideoEl = '.js-edit-adobe-video';

      const setUpDrawer = () => {
        const getEl = document.querySelector('.wrp-studio-drawer');
        getEl.classList.remove('z-index-1050');
      };

      const setUpAdobe = (mediaType, adobeProjectId) => {
        let fileInput;
        let fileContainer;
        let allowedFileTypes;
        let outputType;

        if (mediaType === 'image') {
          allowedFileTypes = ['image/png', 'image/jpeg'];
          outputType = 'base64';
        } else if (mediaType === 'video') {
          allowedFileTypes = ['video/mp4'];
          outputType = 'url';
        }

        async function fetchVideo(url, videoFileReader) {
          let blob = await fetch(url).then((r) => r.blob());
          videoFileReader.readAsArrayBuffer(blob);
        }

        const getActiveVariantTabId = () => {
          const activeElement = document.querySelector('.js-variant-tabs__tablist .active');

          if (activeElement) {
            let dataTarget = activeElement.dataset.target;
            const splitDataTarget = dataTarget.split('');
            const getVariantIDFromTarget = splitDataTarget[splitDataTarget.length - 1];

            return getVariantIDFromTarget;
          }
        };

        const getAssetName = (variantIndexId, fallbackName) => {
          let variantNameInput = document.querySelectorAll('.js-variant-field-name')[variantIndexId];
          let storyIslandName = document.querySelector('.js-island-label').textContent;
          let variantName = fallbackName;

          if (variantNameInput && storyIslandName) {
            if (variantNameInput.value) {
              let islandLabel = storyIslandName.trim().split(' ').pop();
              variantName = `${islandLabel} ${variantNameInput.value}`;
            } else {
              variantName = storyIslandName;
            }
          }
          return variantName;
        };

        const observer = new MutationObserver(getActiveVariantTabId);

        observer.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true,
        });

        const variantIndexId = getActiveVariantTabId();

        observer.disconnect();

        const createDesignCallback = {
          onCancel: () => {},
          onPublish: (publishParams) => {
            const localData = {
              project: publishParams.projectId,
              asset: publishParams.asset[0].data,
              filename: publishParams.asset[0].fileName,
            };

            //  =======================================================================================================
            // VIDEO
            //  =======================================================================================================
            if (mediaType === 'video') {
              // Pass through adobe's project id as the query param to the , so it can be saved against the image blob right away
              let element = document.getElementById(`js-file-upload__adobe_project_id-video-${variantIndexId}`);
              let fileUploadUrl = element.dataset.fileUploadUrl;
              element.dataset.fileUploadUrl = `${fileUploadUrl}&adobe_project_id=${localData.project}`;

              // Initialise file reader so it can create data from a blob that we can get from a url
              let videoFileReader = new FileReader();

              // Once the readAsArrayBuffer is successful,
              videoFileReader.addEventListener('load', function () {
                // get the result from the function
                let arrayBuffer = videoFileReader.result;

                // Find the file input element
                let videoFileInput = document.querySelector(`#video-container-${variantIndexId} .js-file-upload__field`);

                // And initialise the container where to place the file to
                let videoFileContainer = new DataTransfer();

                // Create the file from data
                let variantName = getAssetName(variantIndexId, 'Adobe_Express_Video');
                let videoFile = new File([new Uint8Array(arrayBuffer)], `${variantName}.mp4`, { type: 'video/mp4', lastModified: new Date().getTime() });

                videoFileContainer.items.add(videoFile);
                // Place the file to the input field (value/files) bypassing the read-only validation
                videoFileInput.files = videoFileContainer.files;

                // Trigger the change event for the input field so we see the video file displayed after the upload
                let changeEvent = new Event('change', { bubbles: true });
                videoFileInput.dispatchEvent(changeEvent);
              });

              fetchVideo(localData.asset, videoFileReader);
            }

            //  =======================================================================================================
            // IMAGE
            //  =======================================================================================================
            if (mediaType === 'image') {
              // Finds the input field to target and add the image file to
              fileInput = document.querySelector(`#image-container-${variantIndexId} .js-file-upload__field`);

              // Create a DataTransfer (container) to place the created file in there
              fileContainer = new DataTransfer();

              // Create a file from the Adobe response for image data (base64):
              // Encoding
              // Read more here: https://stackoverflow.com/a/71481069
              let imageDataArray = localData.asset.split(',');
              let mimeType = imageDataArray[0].match(/:(.*?);/)[1];
              let binaryString = atob(imageDataArray[1]);
              let lengthOfBinary = binaryString.length;
              let encodedArray = new Uint8Array(lengthOfBinary);
              let imageFileExtension = mimeType.split('/')[1];

              while (lengthOfBinary--) {
                encodedArray[lengthOfBinary] = binaryString.charCodeAt(lengthOfBinary);
              }

              // Create file from encoded array
              let variantName = getAssetName(variantIndexId, 'Adobe_Express_Image');
              let imageFile = new File([encodedArray], `${variantName}.${imageFileExtension}`, {
                type: mimeType,
                lastModified: new Date().getTime(),
              });

              // Place the file to a datatransfer
              fileContainer.items.add(imageFile);

              // Place the DataTransfer file to the input field files
              fileInput.files = fileContainer.files;

              // Trigger file upload handling
              let changeEvent = new Event('change', { bubbles: true });
              fileInput.dispatchEvent(changeEvent);

              updateSelectorClasses(`#image-container-${variantIndexId} .js-create-adobe-image`, ['w-50'], ['d-none']);
              updateSelectorClasses(`#js-local-file-upload-${variantIndexId}`, ['d-flex', 'flex-space-evenly'], []);
              updateSelectorClasses(`IMAGE_UPLOAD`, ['w-50'], []);

              // Insert adobe's project id to the hidden form input, so it can be saved against the image blob right away
              document.getElementById(`js-file-upload__adobe_project_id-${variantIndexId}`).value = localData.project;
            }
          },
          onError: (err) => {
            console.error('Error received is', err.toString());
          },
        };

        let adobeParams = {
          callbacks: createDesignCallback,
          modalParams: {
            borderRadius: 20,
            padding: 10,
            backgroundColor: 'darkest',
          },
          inputParams: {
            templateType: 'video',
            editorPanelView: 'templates',
            canvasSize: {
              height: 1080,
              width: 1920,
              unit: 'px',
            },
            exportOptions: [
              {
                id: 'stornaway-export',
                label: 'Save and Export to Stornaway',
                target: 'Host',
                closeTargetOnExport: true,
                variant: 'accent',
                optionType: 'button',
                buttonType: 'custom',
              },
            ],
          },
          outputParams: {
            outputType: outputType,
            allowedFileTypes: allowedFileTypes,
          },
        };

        if (adobeProjectId) {
          adobeParams.inputParams.projectId = adobeProjectId;
          ccEverywhere.editDesign(adobeParams);
        } else {
          ccEverywhere.createDesign(adobeParams);
        }
      };

      // CREATE
      $(document).on('click', createAdobeImageEl, () => {
        setUpDrawer();
        setUpAdobe('image');
      });

      $(document).on('click', createAdobeVideoEl, () => {
        setUpDrawer();
        setUpAdobe('video');
      });

      // EDIT
      $(document).on('click', editAdobeImageEl, (e) => {
        const adobeProjectId = e.target.dataset.adobeProjectId;

        setUpDrawer();
        setUpAdobe('image', adobeProjectId);
      });

      $(document).on('click', editAdobeVideoEl, (e) => {
        const adobeProjectId = e.target.dataset.adobeProjectId;

        setUpDrawer();
        setUpAdobe('video', adobeProjectId);
      });
    };

    document.body.appendChild(script);
  })(document, 'https://sdk.cc-embed.adobe.com/v3/CCEverywhere.js');
}
